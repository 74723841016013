import Mock from 'mockjs';

const presetList = [
  // {
  //   id: 1,
  //   name: 'workplace',
  //   title: '首页-工作台',
  //   icon: 'DashboardOutlined',
  //   target: '_self',
  //   path: '/workplace',
  //   component: '@/pages/workplace',
  //   renderMenu: true,
  //   parent: null,
  //   permission: null,
  //   cacheable: true,
  // },
  // 父级路由示例，父级路由需要配置一个视图组件
  // {
  //   id: 3,
  //   name: 'applet',
  //   title: '小程序管理',
  //   path: '/applet',
  //   icon: 'ProfileOutlined',
  //   badge:"",
  //   target:'_self',
  //   permission: null,
  //   component: '@/components/layout/LinkView.vue',
  //   renderMenu: true,
  //   parent: null,
  // },
  {
    id: 4,
    name: 'users',
    title: '用户管理',
    path: '/users',
    badge:'',
    icon: 'UserOutlined',
    permission: null,
    component: '@/pages/users',
    renderMenu: true,
    // parent: 'applet',
    cacheable:true
  },
  {
    id: 5,
    name: 'products',
    title: '商品管理',
    path: '/products',
    badge:'',
    icon: 'ShopOutlined',
    permission: null,
    component: '@/pages/products',
    renderMenu: true,
    // parent: 'applet',
    cacheable:true
  },
  {
    id: 6,
    name: 'cars',
    title: '车辆管理',
    path: '/cars',
    badge:'',
    icon: 'CarOutlined',
    permission: null,
    component: '@/pages/cars',
    renderMenu: true,
    // parent: 'applet',
    cacheable:true
  },

];

function getMenuList() {
  const menuStr = localStorage.getItem('system-menu');
  let menuList = [];
  if (!menuStr) {
    menuList = presetList;
    localStorage.setItem('system-menu', JSON.stringify(menuList));
  } else {
    menuList = JSON.parse(menuStr);
  }
  return menuList;
}


Mock.mock('api/menu', 'get', ({}) => {
  let menuList = getMenuList();
  const menuMap = menuList.reduce((p, c) => {
    p[c.name] = c;
    return p;
  }, {});
  menuList.forEach((menu) => {
    menu.renderMenu = !!menu.renderMenu;
    if (menu.parent) {
      const parent = menuMap[menu.parent];
      parent.children = parent.children ?? [];
      parent.children.push(menu);
    }
  });
  return {
    message: 'success',
    code: 0,
    data: menuList.filter((menu) => !menu.parent),
  };
});



import { createPinia } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist'
export { storeToRefs } from 'pinia';
export * from './account';
export * from './menu';
export * from './setting';
export * from './loading';

const pinia = createPinia();
pinia.use(piniaPluginPersist) // pinia持久化存储插件

export default pinia;

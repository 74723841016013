export interface LoginForm {
  userAccount: string;
  password: string;
}

export interface Response<T = never> {
  message: string;
  msg:string;
  code: number;
  data: T;
}

export function isResponse(obj: any): obj is Response<any> {
  return typeof obj === 'object' && (obj.message !== undefined || obj.msg !== undefined) && obj.code !== undefined;
}

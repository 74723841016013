import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'login',
    redirect: '/login',
    meta: {
      title: '首页',
      renderMenu: false,
      icon: 'CreditCardOutlined',
    },
  },
  {
    path: '/login',
    name: '登录',
    meta: {
      icon: 'LoginOutlined',
      view: 'blank',
      target: '_blank',
      cacheable: false,
      renderMenu:false
    },
    component: () => import('@/pages/login'),
  },
  // {
  //   path: '/applet',
  //   name: '小程序管理',
  //   meta: {
  //     renderMenu: true,
  //     cacheable:true,
  //   },
  //   component: () => import('@/components/layout/LinkView.vue'),
  //   children:[
  //     {
  //       path: '/users',
  //       name: '用户管理',
  //       meta: {
  //         icon: 'LoginOutlined',
  //         // view: 'blank',
  //         // target: '_blank',
  //         cacheable: true,
  //       },
  //       component: () => import('@/pages/users'),
  //     },
  //     {
  //       path: '/products',
  //       name: '商品管理',
  //       meta: {
  //         icon: 'LoginOutlined',
  //         // view: 'blank',
  //         // target: '_blank',
  //         cacheable: true,
  //       },
  //       component: () => import('@/pages/products'),
  //     },
  //     {
  //       path: '/cars',
  //       name: '车辆管理',
  //       meta: {
  //         icon: 'LoginOutlined',
  //         // view: 'blank',
  //         // target: '_blank',
  //         cacheable: true,
  //       },
  //       component: () => import('@/pages/cars'),
  //     },
  //   ]
  // },
  {
    path: '/personal',
    name: '个人中心',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/personal')
  },
  {
    path: '/front',
    name: '前端',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/components/layout/FrontView.vue'),
    children: [

      {
        path: '/home',
        name: '首页',
        meta: {
          view: 'blank',
        },
        component: () => import('@/pages/home'),
      },
    ],
  },
  {
    path: '/403',
    name: '403',
    props: true,
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/Exp403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: {
      icon: 'CreditCardOutlined',
      renderMenu: false,
      cacheable: false,
      _is404Page: true,
    },
    component: () => import('@/pages/Exp404.vue'),
  },
];

export default routes;

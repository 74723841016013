import { defineStore } from 'pinia';
import http from './http';
import { Response } from '@/types';
import { useMenuStore } from './menu';
import { useAuthStore } from '@/plugins';
import { useLoadingStore } from './loading';

export interface Profile {
  account: Account;
  permissions: string[];
  role: string;
}
export interface Account {
  id:string;
  userAccount: string;
  userCname:string,
  userPhone:string,
  userPassword:string,
  avatar: string;
}

export type TokenResult = {
  token: string;
  expires: number;
};
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      logged: true,
    };
  },
  persist: { enabled: true },
  actions: {
    async login(userAccount: string, password: string) {
      const { setAuthLoading } = useLoadingStore();
      setAuthLoading(true);
      return http
        .request<any, Response<any>>('/login/account', 'POST_JSON', { userAccount, password })
        .then(async (response) => {
          if (response.code == 0) {
            this.logged = true;
            http.setAuthorization(`Bearer ${document.cookie}`);
            // 登录时获取并存储用户id
            const {id} = response.data;
            Object.assign(this.account,{"id":id})
            await useMenuStore().getMenuList();
            // return response.data;
          } else {
            return Promise.reject(response);
          }
        }).finally(() => setAuthLoading(false));
    },
    async logout() {
      const { setAuthLoading } = useLoadingStore();
      setAuthLoading(true);
      return http.request('/login/logout', 'get')
        .then(async (res) => {
          localStorage.removeItem('system-menu');
          http.removeAuthorization()
          this.logged = false
        }).finally(() => setAuthLoading(false));
    },
    async profile(id) {
      const { setAuthLoading } = useLoadingStore();
      setAuthLoading(true);
      return http
        .request<any, Response<any>>('/user', 'get',{"id":id})
        .then((response) => {
          if (response?.code == 0) {
            const { setAuthorities } = useAuthStore();
            const {userAccount, userCname, userPhone, userPassword, permission, role } = response?.data;
            Object.assign(this.account, { "userAccount": userAccount })
            Object.assign(this.account, { "userCname": userCname })
            Object.assign(this.account, { "userPhone": userPhone })
            Object.assign(this.account, { "userPassword": userPassword })
            this.permission = permission;
            this.role = role;
            setAuthorities(permission);
            return response.data;
          } else {
            return Promise.reject(response);
          }
        })
        .finally(() => setAuthLoading(false));
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
  },
});

import { useThemeStore } from 'stepin/es/theme-provider';
import { useSettingStore } from '@/store';


import SideDarkImg from '@/assets/theme/side-dark.png';
import HeaderDarkImg from '@/assets/theme/header-dark.png';
import LightImg from '@/assets/theme/light.png';

export function configTheme(key: string) {
  const { setBgSeriesColors } = useThemeStore();
  const { setNavigation } = useSettingStore();
  switch (key) {
    case 'night':
      setBgSeriesColors({ 'bg-base': '#1D1D1F' });
      break;
    case 'header-dark':
      setNavigation('head');
      break;
    default:
      setNavigation('side');
  }
}

export const themeList: Theme.ThemeConfig[] = [
  {
    title: '亮色模式',
    key: 'light',
    imgUrl: LightImg,
    config: { color: { middle: { 'bg-base': '#fff' } } , size: { 'width-side': '190px' } },
  },
  {
    title: '侧边暗色菜单',
    key: 'side-dark',
    imgUrl: SideDarkImg,
    config: { color: { middle: { 'bg-base': '#fff', 'bg-side': '#3467F9'} ,}, size: { 'width-side': '220px' } ,},
  },
  {
    title: '顶部暗色菜单',
    key: 'header-dark',
    imgUrl: HeaderDarkImg,
    config: { color: { middle: { 'bg-base': '#fff', 'bg-header': '#001129' } } },
  },

];

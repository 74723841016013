<script lang="ts" setup></script>
<template>
  <!-- 页脚 -->
  <div class="page-footer">
    <div class="links">
      <a class="link" href="#" > 报价系统 首页 </a>
    </div>
    <div class="copyright">
      <!-- Copyright
      <CopyrightOutlined class="icon-copyright" />  -->
      <a rel="nofollow" href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023080545号 © 广东超境新材料科技有限公司</a>
    </div>
  </div>
</template>

<style scoped lang="less">
  .page-footer {
    text-align: center;
    @apply text-gray-400;

    .links {
      display: flex;
      justify-content: center;

      .link {
        @apply hover:text-gray-400 pl-4 pr-4;
      }
    }

    .copyright {
      margin-top: 8px;

      .icon-copyright {
        margin: 0;
      }
    }
  }
</style>

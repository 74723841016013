<template>
  <a-config-provider :getPopupContainer="getPopupContainer" :locale="zhCN">
    <!-- 主题配置组件-ThemeProvider-设置 -->
    <ThemeProvider is-root v-bind="themeConfig" :apply-style="false">
      <!-- <a-image src="src/assets/logo/logo2.png"></a-image> -->
      <stepin-view system-name="超境报价管理系统" :class="`${contentClass}`" :user="user" :navMode="navigation" :useTabs="useTabs"
        :themeList="themeList" v-model:theme="theme" @themeSelect="configTheme" logo-src="@/assets/logo/logo.png">
        <!-- logo-src="url(src/assets/logo/logo2.png)" -->
        <template #pageFooter>
          <PageFooter />
        </template>
        <template #themeEditorTab>
          <a-tab-pane tab="其它" key="other">
            <Setting />
          </a-tab-pane>
        </template>
      </stepin-view>
    </ThemeProvider>
  </a-config-provider>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAccountStore, useMenuStore, useSettingStore, storeToRefs } from '@/store';
import { PageFooter, HeaderActions } from '@/components/layout';
import Setting from './components/setting';
import { configTheme, themeList } from '@/theme';
import { ThemeProvider } from 'stepin';
import { computed } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'
dayjs.locale('zhCN')
const { logout, account, profile } = useAccountStore();

// 获取个人信息
if (account?.id) {
  profile(account.id).then((response) => {
    // debugger
    user.name = response?.userAccount;
    // user.avatar = account.avatar;
  });
}



const router = useRouter();

useMenuStore().getMenuList();

const { navigation, useTabs, theme, contentClass } = storeToRefs(useSettingStore());
const themeConfig = computed(() => themeList.find((item) => item.key === theme.value)?.config ?? {});

const user = reactive({
  name: account.userAccount,
  // avatar: avatar,
  menuList: [
    { title: '个人中心', key: 'personal', icon: 'UserOutlined', onClick: () => router.push('/personal') },
    { type: 'divider' },
    {
      title: '退出登录',
      key: 'logout',
      icon: 'LogoutOutlined',
      onClick: () => logout().then(() => router.push('/login')),
    },
  ],
});
// user.name=account.userAccount
function getPopupContainer() {
  return document.querySelector('.stepin-layout');
}
</script>

<style lang="less">
.stepin-view {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: theme('colors.primary.500');
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: theme('colors.primary.400');

    &:hover {
      background-color: theme('colors.primary.500');
    }
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 4px;
    background: theme('backgroundColor.layout');
  }
}

html {
  height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0;
  height: 100vh;
  overflow-y: hidden;
}
.stepin-logo{
  display: block !important;
  height: 100px !important;
  background-color: #fff !important;
  text-align: center;
}
.logo-img{
  height: 60% !important;
  // margin: 18px auto;
  // display: block;
}
.system-name{
  color: rgb(52, 103, 249) !important;
  font-weight: bold !important;
}
.stepin-header{
  background-color: #fff !important;
  color: #333 !important; 
}

.stepin-img-checkbox {
  @apply transition-transform;

  &:hover {
    @apply scale-105~"-translate-y-[2px]";
  }

  img {
    @apply shadow-low rounded-md transition-transform;
  }
}
</style>
